/* eslint-disable no-undef */
import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export const Head = () => (
  <>
    <title>Referencje od klientów | Geodezja Olsztyn | Satysfakcja i profesjonalizm</title>
    <meta
      name="description"
      content="Przeczytaj referencje od naszych zadowolonych klientów. Zaufaj naszemu doświadczeniu w świadczeniu usług geodezyjnych, w tym obsługi lotnisk i lądowisk."
    />
    <link rel="canonical" href="https://geo-partner.pl/referencje/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  p {
    line-height: 150%;
  }

  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }
`;

const Slider = styled.div`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Gallery = styled.ul`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  max-width: 800px;
  margin: 0 auto;
  padding: 0;

  li {
    border: 1px solid #a6a6a6;
    transition: color 200ms ease-in;

    &:hover {
      border-color: ${({ theme }) => theme.secondary};
    }
  }

  @media only screen and (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    max-width: 960px;
  }
`;

const imageData = [
  { alt: 'Port lotniczy Olsztyn-Mazury', ref: 'ref_10' },
  { alt: 'Arkas-Projekt', ref: 'ref_1' },
  { alt: 'Budimex', ref: 'ref_2' },
  { alt: 'G&M', ref: 'ref_3' },
  { alt: 'MG Pomorze', ref: 'ref_4' },
  { alt: 'Skańska S.A.', ref: 'ref_5' },
  { alt: 'Urząd Miasta Olsztyn', ref: 'ref_6' },
  { alt: 'URS/Scott Wilson', ref: 'ref_7' },
  { alt: 'Zarząd Melioracji i Urządzeń Wodnych w Osztynie', ref: 'ref_8' },
  { alt: 'Zarząd Melioracji i Urządzeń Wodnych w Osztynie', ref: 'ref_9' },
];

const AboutOffice = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_6)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Referencje</h1>

        <Gallery>
          {imageData.map(({ alt, ref }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <Zoom>
                <img alt={alt} src={data[ref].publicURL} />
              </Zoom>
            </li>
          ))}
        </Gallery>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_6: file(relativePath: {regex: "/hero\/6.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    ref_1: file(relativePath: {regex: "/reference\/big\/1.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_2: file(relativePath: {regex: "/reference\/big\/2.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_3: file(relativePath: {regex: "/reference\/big\/3.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_4: file(relativePath: {regex: "/reference\/big\/4.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_5: file(relativePath: {regex: "/reference\/big\/5.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_6: file(relativePath: {regex: "/reference\/big\/6.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_7: file(relativePath: {regex: "/reference\/big\/7.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_8: file(relativePath: {regex: "/reference\/big\/8.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_9: file(relativePath: {regex: "/reference\/big\/9.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    ref_10: file(relativePath: {regex: "/reference\/big\/10.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
  }
`;

export default AboutOffice;
